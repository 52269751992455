@use "variables" as *;

.sidebar.open ~ .main-section {
  left: $side-menu-full-width;
  width: calc(100% - $side-menu-full-width);
}

.sidebar,
.main-section {
  max-height: $app-height;
}
